
/* eslint-disable */
// Autogenerated
// -------------
// gulpfile.ts/wasm.ts --> generateBuildInfo

export const Build = {
    version: "0.73.8 (355c2db69904d6aa8dd383a6dd41f291)",
    buildSeed: 1707622453090,
    "wdosbox.wasm": {
        "size": 1461333,
        "gzSize": 499189
    },
    "wdosbox.js": {
        "size": 124967,
        "gzSize": 32629
    },
    "wlibzip.wasm": {
        "size": 110694,
        "gzSize": 51335
    },
    "wlibzip.js": {
        "size": 77090,
        "gzSize": 19984
    }
,
};
